document.addEventListener("DOMContentLoaded", function(){
  AOS.init();

  $('nav#menu').mmenu({
    drag: true,
    navbar: {
      title: "選單"
    },
    pageScroll: {
      scroll: true,
      update: true
    },
    'extensions': [
      'theme-dark',
      'fx-menu-fade',
      'fx-listitems-slide'
    ]
  }, {
    classNames: {
      fixedElements: {
        fixed: 'fix',
        sticky: 'is-sticky'
      }
    }
  })
  // $('#pageHeader').sticky({topSpacing: 0})
  // $('#pageHeader').on('sticky-end', function () { $(this).parent('.sticky-wrapper').css({height: 'auto'}) }) // sticky 補丁
  window.onscroll = function() {onWindowScroll()};
  const header = document.getElementById("pageHeader");
  const stickyOffset = 160;
  let prevOffset = 0
  function onWindowScroll() {
    let yOffset = window.pageYOffset
    if (yOffset > prevOffset ) {
      header.classList.add("isHide");
    } else {
      header.classList.remove("isHide");
      if (yOffset > stickyOffset) {
        header.classList.add("isSticky");
      } else {
        header.classList.remove("isSticky");
      }
    }
    prevOffset = yOffset
  }

  $('.hero-banner').slick({
    infinite: false,
    cssEase: 'linear',
    dots: true,
    autoplay: true,
    autoplaySpeed: 2800,
    easing: 'easeOutElastic',
  });

  $('.ecshop-slick').slick({
    infinite: false,
    dots: false,
    infinite: true,
    speed: 500,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 500,
  });

  $('.warehouse-customers').slick({
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 2,
    dots: true,
    infinite: true,
    // centerMode: true,
    // variableWidth: true,
    // slidesPerRow: 2,
    rows: 2,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
          autoplay: true,
          autoplaySpeed: 2000,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
        }
      }
    ]
  });

  // contact form


});

function smoothScrollto (id) {
  let target = document.querySelector(`#${id}`)
  target.scrollIntoView({
    behavior: 'smooth'
  });
}

let lastKnownScrollPosition = 0;
let ticking = false;
let topAnchor = document.getElementById('goTop')
topAnchor?.addEventListener("click", ()=>{window.scrollTo({top: 0, behavior: 'smooth'});});

function whenScroll(scrollPos) {
  // 依據捲動位置進行某些操作
  if( scrollPos > 200 && !!topAnchor ) {
    topAnchor.classList.add('show')
  } else {
    topAnchor.classList.remove('show')
  }
}

window.addEventListener('load', AOS.refresh)

document.addEventListener('scroll', function(e) {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(function() {
      whenScroll(lastKnownScrollPosition);
      ticking = false;
    });

    ticking = true;
  }
});